.flexDiv{
    display: flex;
}
.navBarDiv{
    display: flex;
    justify-content: space-between;
    padding: 25px 30px;
    border-bottom: 1px solid #D5D5D5;
}
.title{
    font-size: 50px;
    margin: 0;
    color: #99999F;
    font-family: 'The Star in the Midnight';
}
.imgStyle{
    align-self: center;
}
.notificationStyle{
    @extend .imgStyle;
    margin-right: 20px;
    width: 50px;
}
.profileStyle{
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-size: 55px;
    overflow: hidden;
}

.profileStyle > img{
    width: 50px;
    height: 50px;
    background-color: white;
}

.dropdownMenu{
    position: absolute;
    background: white;
    padding: 25px;
    right: 50px;
    margin-top: 13px;
    border-radius: 10px;
    width: 250px;
}
.alignDiv{
    align-self: center;
}
.menuItemStyle{
    font-family: 'Aspira';
    font-size: 20px;
    font-weight: bold;
    color: #7B6F67;
    border-bottom: 1px solid #D5D5D5;
    text-align: center;
}