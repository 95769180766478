.container{
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: unset;
    margin: unset;
}

.scrollArea{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
}

.pageWrapper{
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.contentContainer{
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.navBarContainer{
    /* min-width: 100%; */
    position: relative;
    z-index: 1500;
}

.sideMenuContainer{
    min-width: 150px;
    height: 100%;
}


.contentContainer > *{
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100%;
    height: 100%;
    flex: 1;
}

.loader{
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}