
.contentStyle{
    max-width: 765px;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentStyle > div{
    display: unset;
    width: unset;
    background-color: unset;
    max-height: 80%;
    overflow: visible;
    overflow-y: overlay;
    min-width: fit-content;
    max-width: 90%;
    border: none;
}

.popUpContent{
    width: 763px;
}
.wrapperDiv{
    padding: 24px 65px;
}
