.mainDiv{
    background: url('../../Media/Images/BG1.png') center center;
    background-size: cover;
    display: flex;
    height: 100%;
    width: 100%;
}
.loginDiv{
    background: #ffffff;
    padding: 40px;
    border-radius: 0 40px;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 80%;
    max-height: 690px;
}
.imgStyle{
    align-self: center;
    margin-bottom: 40px;
}
.inputFieldStyle{
    border-radius: 30px;
    box-shadow: 0px 0 10px rgb(0 0 0 / 16%);
    border: none;
    padding: 10px 25px;
    text-align: left;
    font-family: "Aspira";
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #7B6F67;
    max-width: 300px;
}
.inputFieldStyle::placeholder{
    color: #D5D5D5;
    text-align: center;
}
.inputFieldStyle:focus{
    border: none;
}
.btnStyle{
    width: 240px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Aspira';
    margin: auto;
}