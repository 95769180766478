.tileStyle{
    border-radius: 30px;
    width: 80%;
    max-width: 450px;
    padding: 40px 30px 0 30px;
    background: #ffffff;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}
.lastTileStyle{
    @extend .tileStyle;
    margin-right: 0;
    padding: 30px;
}
.mainDiv{
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
}
.container{
    display: flex; 
    justify-content: space-evenly;
}
.titleStyle{
    color: #7B6F67;
    font-family: 'Aspira';
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    line-height: 20px;
}
.subTitleStyle{
    @extend .titleStyle;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 2px;
    // line-height: 15px;
}
.inputStyle{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    width: 100%;
    padding: 5px 8px;
    background: #fafafa;
    text-align: left;
}
.wrapperDiv{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}
.timeDiv{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
}
.timeInput{
    grid-row-start: 2;
    max-width: 130px;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    padding: 5px 8px;
    background: #fafafa;
    text-align: center;
}
.attachmentInpuStyle{
    @extend .inputStyle;
    border: 1px dashed #d5d5d5;
    padding: 30px;
    position: relative;
}
.attachmentInpuStyle::placeholder{
    font-family: 'Aspira';
    font-size: 18px;
    font-weight: normal;
    color: #7B6F67;
}
.attachmentUpload{
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.btnStyle{
    font-size: 18px;
    font-family: "Aspira";
    font-weight: bold;
    width: 240px;
    margin: auto auto 0 auto;
    padding: 12px;
}
.checkboxStyle{
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
.membersDiv{
    @extend .inputStyle;
    display: flex;
    flex-direction: column;
}
.attachmentTitle{
    font-family: 'Aspira';
    color: #7B6F67;
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
}
// .checkboxStyle:checked{
    // background: red;
    // visibility: hidden;
// }


// input[type="checkbox"]:checked + label::after {
//     content: '';
//     position: absolute;
//     width: 1.2ex;
//     height: 0.4ex;
//     background: rgba(0, 0, 0, 0);
//     top: 0.9ex;
//     left: 0.4ex;
//     border: 3px solid blue;
//     border-top: none;
//     border-right: none;
//     -webkit-transform: rotate(-45deg);
//     -moz-transform: rotate(-45deg);
//     -o-transform: rotate(-45deg);
//     -ms-transform: rotate(-45deg);
//     transform: rotate(-45deg);
//  }
 
//  input[type="checkbox"] {
//     line-height: 2.1ex;
//  }
 
//  input[type="radio"],
//  input[type="checkbox"] {
//      position: absolute;
//      left: -999em;
//  }
 
//  input[type="checkbox"] + label {
//      position: relative;
//      overflow: hidden;
//      cursor: pointer;
//  }
 
//  input[type="checkbox"] + label::before {
//     content: "";
//     display: inline-block;
//     vertical-align: -25%;
//     height: 2ex;
//     width: 2ex;
//     background-color: white;
//     border: 1px solid rgb(166, 166, 166);
//     border-radius: 4px;
//     box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
//     margin-right: 0.5em;
//  }
//  .checkboxStyle:checked::before{
//      content: '';
//  }
//  .checkboxStyle:checked::after{
//      content: '';
//  }
 .checkbox{
    border: 1px solid #7B6F67;
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 2px;
    left: 0;
    top: 3px;
 }
 .checkboxDiv{
     position: relative;
     display: flex;
     justify-content: space-evenly;
     max-width: 130px;
 }
 .checkboxStyle:checked ~ .checkbox{
    background: url('../../Media/Images/Icon\ awesome-check.png') no-repeat center;
 }
//  .checkboxStyle:checked .checkbox{
//     display: block;
//  }

.displayFlex{
    display: flex;
    margin-bottom: 40px;
}
.inputDiv{
    position: relative;
}
.radioStyle{
    position: absolute;
    width: 100%;
    opacity: 0;
    height: 100%;
    top: 0;
}
.radioStyle:checked + label{
    background: #D5D5D5;
}
.labelStyle{
    background: #ffffff;
    padding: 10px 40px;
    border-radius: 10px 0 0 10px;
    color: #7B6F67;
    font-family: 'Aspira';
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
}
.leftLabel{
    @extend .labelStyle;
    border-right: 1px solid #D5D5D5;
}
.rightLabel{
    @extend .labelStyle;
    border-radius: 0 10px 10px 0;
}
.topicsContainer{
    background: #ffffff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
}
.topicsTitle{
    color: #7B6F67;
    font-family: 'Aspira';
    font-weight: bold;
    text-align: left;
    font-size: 26px;
    margin-bottom: 20px;
}
.topicsTableTitle{
    @extend .topicsTitle;
    font-size: 20px;
    margin-bottom: 0;
}
.contentTitle{
    @extend .topicsTitle;
    font-size: 16px;
    margin-bottom: 0;
}
.topicsTableHeaderDiv{
    background: #F5F2F1;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 40% 60%;
    border-radius: 10px 10px 0 0;
}
.topicsTableDiv{
    border: 1px solid #F5F2F1;
    display: flex;
    flex-direction: column;
}
.topicsInnerDiv{
    display: flex;
    padding: 12px;
}
.topicsContentDiv{
    display: grid;
    grid-template-columns: 40% 60%;
}
.addIcon{
    align-self: center;
    margin-right: 10px;
}
.newTopicDiv{
    background: #FFF7F7;
    padding: 12px;
    display: grid;
    grid-template-columns: 40% 50% 10%;
}
.inputFieldStyle{
    border: 1px solid #D5D5D5;
    font-size: 20px;
    line-height: 24px;
    max-height: 30px;
    max-width: 360px;
}
.inputFieldStyle:focus{
    border: 1px solid #D5D5D5;
}
.textAreaStyle{
    @extend .inputFieldStyle;
    max-width: 450px;
}
.tableItemStyle{
 @extend .contentTitle;
 border: 1px solid #D5D5D5;
 padding: 12px;
}