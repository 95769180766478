.mainDiv{
    padding: 40px;
}
.tableDiv{
    background: #ffffff;
    width: 100%;
    padding: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
}

.displayFlex{
    display: flex;
}
.btnsDiv{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.btnStyle{
    width: 17%;
    font-family: 'Aspira';
}
.leftBtnStyle{
    @extend .btnStyle;
    margin-right: 20px;
}