.flexDiv{
    display: flex;
}
.content{
    width: 100%;
    background-color: #F5F2F1;
}
.navBarDiv{
    display: flex;
    justify-content: space-between;
    padding: 25px 50px;
    border-bottom: 1px solid #D5D5D5;
}
.title{
    font-size: 70px;
    margin: 0;
    color: #99999F;
}
.imgStyle{
    align-self: center;
}
.notificationStyle{
    @extend .imgStyle;
    margin-right: 40px;
}
.profileStyle{
    @extend .imgStyle;
    margin-right: 10px;
}
.wrapperDiv{
    padding: 40px 40px 20px 40px;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;

}

.graphBlockTitle{
    @extend .whiteBlockTitle;
    
    display: block !important;
    z-index: 200;
}

.graphWrapper{
    @extend .whiteBlock;
    padding: 20 0 !important;
    width: 100%;
    overflow: hidden;
    max-width: 100% !important;
    margin: 40px 0 40px 0 ;
}

.graphWrapper > div{
    position: relative;
}

.welcomeDiv{
    background: url('../../Media/Images/Gradient.png') no-repeat center;
    border-radius: 0 30px;
    background-size: 100%;
}
.welcomeText{
    padding: 30px 40px 30px 40px;
}
.gradientDiv{
    display: flex;
    justify-content: space-between;
    border-radius: 0 30px;
    max-width: 1400px;
    margin: auto;
}
.circleDiv{
    width: 100px;
    height: 100px;
    border: 5px solid white;
    border-radius: 120px;
    margin: 30px 40px 20px 0;
    background: url('../../Media/Images/avatar.jpg') no-repeat center;
    background-size: 115px;
    overflow: hidden;
}

.circleDiv > img{
    background-color: white;
    width: 100px;
    height: 100px;
    
}

.textStyle{
    text-align: left;
    margin: 0;
    color: white;
    font-family: 'Aspira';
}
.titleStyle{
    @extend .textStyle;
    font-size: 40px;
}
.subTitle{
    @extend .textStyle;
    font-size: 20px;
}
.whiteBlock{
    border-radius: 0 30px;
    background: #ffffff;
    padding: 20px 20px 20px 40px;
    max-width: 710px;
    width: 100%;
}
.leftWhiteBlock{
    @extend .whiteBlock;
    margin-right: 20px;
}
.whiteBlocksWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 50px auto;
}
.nextMeetingBtn{
    width: 300px;
    background: #F8B434;
    border-radius: 10px;
    padding: 10px 0;
    margin: 0 auto;
}
.nextMeetingBtnText{
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    font-family: 'Aspira';
    text-align: center;
}
.whiteBlockTitle{
    color: #7B6F67;
    font-weight: bold;
    font-family: 'Aspira';
    font-size: 25px;
    text-align: left;
    margin: 0 0 15px 0;
}
.yellowTextStyle{
    font-weight: bold;
    font-family: 'Aspira';
    color: #F8B434;
}
.weekdayStyle{
  @extend .yellowTextStyle;
  font-size: 20px;
  width: 28px;
}
.daydateStyle{
    @extend .yellowTextStyle;
    font-size: 12px;
    border: 1px solid;
    border-radius: 50%;
    padding: 4px 7px;
}
.weeklyCalendarDiv{
    display: flex;
    justify-content: space-evenly;
}
.btnsWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.btnBasicStyle{
    width: 80%;
    font-size: 20px;
    font-family: "Aspira";
    padding: 12px;
}
.btnStyle{
  @extend .btnBasicStyle;
  margin-right: 25px;  
}
.showDiv{
   visibility: visible;
}
.hideDiv{
    visibility: hidden;
}
.blackDot{
    background: #7B6F67;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    align-self: center;
    margin-top: 5px;
}
.datesWrapper{
    display: flex;
    flex-direction: column;
}