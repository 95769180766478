.icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }
  
  /* VARIABLES */
  
  :root {
    --main-color: #1a8fff;
    --main-color-darken: #0176e6;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }
  
  /* GENERAL */
  
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
  }
  
  header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid var(--border-color);
    background: var(--neutral-color);
  }
  
  header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
  }
  
//   header #logo .icon {
    // padding-right: 0.25em;
//   }
  
  main {
    display: block;
    margin: 0 auto;
    margin-top: 2.5em;
    max-width: 50em;
  }
  
  /* GRID */
  
  .rowStyle {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .rowStyle-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
    font-family: 'Aspira';
    font-size: 20px;
    color: #7B6F67;
    font-weight: normal;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  
  /* Calendar */
  
  .calendar {
    display: block;
    position: relative;
    width: 65%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    max-width: 700px;
    margin: 20px auto 40px auto;
    border-radius: 30px;
  }
  .monthStyle{
      font-family: 'Aspira';
      font-size: 28px;
      color: #7B6F67;
      font-weight: bold;
      text-align: center;
  }
  
  .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
  }
  
  .calendar .header .icon:hover {
    transform: scale(1.75);
    transition: 0.25s ease-out;
    color: var(--main-color);
  }
  
//   .calendar .header .icon:first-of-type {
    // margin-left: 1em;
//   }
  
//   .calendar .header .icon:last-of-type {
    // margin-right: 1em;
//   }
  
  .calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: 15px 10px 10px 10px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .cell {
    position: relative;
    height: 3.3em;
    overflow: hidden;
    transition: 0.25s ease-out;
  }
  
  .calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;
  }
  
  .calendar .body .rowStyle {
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .rowStyle:last-child {
    border-bottom: none;
  }
  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    position: absolute;
    font-size: 14px;
    line-height: 1;
    top: 0.75em;
    left: 0.75em;
    font-weight: normal;
    font-family: 'Arial';
    color: #7B6F67;
  }
  
  .calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }
  
  .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }
  
  .calendar .body .selected .bg {
    opacity: 0.05;
    transition: 0.5s ease-in;
  }
  
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
    border-right:0.5px solid #D5D5D5;
    display: flex;
  }
  
  button {
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    display: block;
    margin: 30px auto;
    padding: 0.5rem 1.5rem;
    overflow: hidden;
    border-width: 0;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    background-color: var(--main-color);
    color: #ecf0f1;
    transition: background-color 0.3s box-shadow 1s;
    text-transform: uppercase;
  }
  
  button:hover {
    background-color: var(--main-color-darken);
  }
  
  button:active {
    box-shadow: none;
  }
  .flexDiv{
      display: flex;
      justify-content: space-between;
      height: 100%;
  }
  .rightPanel{
    width: 100%;
    max-width: 350px;
    background: #ffffff;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
  }
  .calendarWrapper{
      width: 100%;
  }
  .dateStyle{
    font-size: 26px;
    font-weight: bold;
    font-family: 'Aspira';
    color: #7B6F67;
  }
  .redCell{
      border: 1px solid #D1202F;
      -webkit-appearance: none;
      -webkit-box-shadow: 0 0 10px #D1202F;
      -moz-box-shadow: 0 0 10px #D1202F;
      box-shadow: 0 0 10px #D1202F;
  }
  .headerStyle{
    display: flex;
    max-width: 280px;
    margin: 30px auto;
  }
  .leftArrow{
    align-self: center;
    transform: rotate(90deg);
  }
  .rightArrow{
    align-self: center;
    transform: rotate(270deg);
  }
  .btnStyle{
    width: 240px;
    font-size: 20px;
    font-family: 'Aspira';
    line-height: 24px ;
    padding: 12px 18px;
  }
  .redCircleStyle{
    align-self: flex-end;
    margin: 9px 12px;
    width: 12px;
  }
  .btnWrapper{
    padding: 20px 0;
    margin: auto auto 0 auto;
  }