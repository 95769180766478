.tileStyle{
    border-radius: 30px;
    display: flex;
    flex-direction: column;

    max-width: 280px  ;
    width: 100%  ;
    padding: 25px 20px 0 20px  ;
    background: #F5F2F1  ;
    margin-right: 20px  ;
}
.lastTileStyle{
    @extend .tileStyle;
    margin-right: 0;
}
.mainDiv{
    // padding: 20px;
    display: flex;
    justify-content: space-evenly;
}
.titleStyle{
    color: #7B6F67;
    font-family: 'Aspira';
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;

    font-size: 21px  ;
    line-height: 25px  ;
}
.subTitleStyle{
    @extend .titleStyle;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 2px;
    line-height: 29px;
}
.inputStyle{
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    width: 100%;
    max-width: 230px  ;
    padding: 3px 8px  ;
    background: #F5F2F1  ;
}
.inputStyle::placeholder{
    font-size: 11px;
    text-align: left;
    font-weight: bold;
    font-family: 'Aspira';
    color: #7B6F67;
}
.wrapperDiv{
    margin-bottom: 15px;
}
.timeDiv{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px; 
}
.timeInput{
    grid-row-start: 2;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    background: #F5F2F1;

    max-width: 110px  ;
    padding: 3px 8px  ;
}
.timeInput::placeholder{
    font-size: 11px;
    text-align: center;
    font-weight: bold;
    font-family: 'Aspira';
    color: #7B6F67;
}
.memberInputStyle{
    @extend .inputStyle;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 20px 15px;
}
.memberLabel{
    font-size: 15px;
    text-align: left;
    font-weight: normal;
    font-family: 'Aspira';
    color: #7B6F67;
}
.reportsTitle{
    margin-bottom: 5px;
    text-align: center;
    border: 0.5px solid #7B6F67;
    border-radius: 2px;
    font-size: 10px;
    font-family: 'Aspira';
    color: #7B6F67;
}
.topicsTitle{
    @extend .reportsTitle;
    font-size: 8px;
    margin-right: 5px;
    padding: 0 5px;
}
.topicsDiv{
    @extend .inputStyle;
    padding-bottom: 40px;
}
.reportsDiv{
    @extend .inputStyle;
    padding: 10px 15px;
}
.attachmentInpuStyle{
    @extend .inputStyle;
    border: 1px dashed #d5d5d5;
    padding: 100px 30px;
    padding: 30px 15px;
    display: flex;
    justify-content: space-evenly;
}
.attachmentInpuStyle::placeholder{
    font-family: 'Aspira';
    font-size: 20px;
    font-weight: normal;
    color: #7B6F67;
}
.btnStyle{
    font-family: 'Aspira';
    font-weight: bold;
    width: 230px  ;
    font-size: 18px  ;
    padding: 7.5px  ;
    margin: auto auto 20px auto;
}
.colFlexDiv{
    display: flex;
    flex-direction: column;
}
.iconStyle{
    align-self: center;
}
.iconLabel{
    color: #7B6F67;
    font-family: 'Aspira';
    font-size: 10px;
    text-align: center;
}