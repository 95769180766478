.headerStyle{
    display: flex;
    max-width: 350px;
    width: 100%;
    margin: auto auto 40px auto;
}
.titleDiv{
    background: #F5F2F1;
    display: grid;
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    grid-template-columns: 40% 40% 20%;
}
.titleStyle{
    color: #7B6F67;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Aspira';
    text-align: left;
    margin-bottom: 0;
    align-self: flex-end;
}
.middleTitleStyle{
    @extend .titleStyle;
    padding: 0 20px;
}
.centeredTitle{
    @extend .titleStyle;
    text-align: center;
}
.rowDiv{
    display: grid;
    border: 1px solid #F5F2F1;
    grid-template-columns: 40% 40% 20%;
}
.cellStyle{
    font-size: 20px;
    font-family: 'Aspira';
    text-align: left;
    border-right: 1px solid #F5F2F1;
    margin-bottom: 0;
    padding: 13px 20px;
    color: #7B6F67;
}
.middleCell{
    @extend .cellStyle;
    text-align: center;
}
.btnsDiv > div{
    display: flex;
    justify-content: space-between;
    padding: 13px 30px;
}