.loader {
    width: 80%;
    max-width: 300px;
    color: #8F3484;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

  }

  
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .screen{
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      background-color: rgb(255, 255, 255,0.65);;
      z-index: 99;
      text-align: center;
      backdrop-filter: blur(8px);
      visibility: hidden;
      justify-content: center;
      align-items: center;
  }



  .visible{
    visibility: visible !important;
  }

  .spinner{
    color: #F8B434;
    width: 10px;
    height: 10px;
  }