.disabled{
    pointer-events: none;
    background: #999 !important;
}

.linkButton {
    background-color: #D1202F;
    color: #FFFFFF;
    text-align: center;
    font-family: 'Open Sans';
    font-weight: normal;
    font-size: 16px;
    max-width: 280px;
    /* width: 100%; */
    border-radius: 10px;
    padding: 10px;
    border-color: transparent;
    height: auto;
}

.inputButton {
    background-color: transparent;
    border: none;
}

.linkButton:hover {
    box-shadow: none !important;
    /* color: #FFFFFF !important; */
}

.linkButton:focus {
    box-shadow: none !important;
    /* color: #FFFFFF !important; */
}

@-moz-document url-prefix() {
    .linkButton:hover {
        box-shadow: none !important;
        /* color: #FFFFFF !important; */
    }
    .linkButton:focus {
        /* color: #FFFFFF !important; */
        box-shadow: none !important;
    }
}

@media screen and (min-width:768px){
    .linkButton{
        max-width: 520px !important;
    }
}