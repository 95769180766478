.mainDiv{
    padding: 40px;
}
.tableDiv{
    background: #ffffff;
    width: 100%;
    padding: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
}
.flexDiv{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
.displayFlex{
    display: flex;
}
.inputDiv{
    position: relative;
}
.radioStyle{
    position: absolute;
    width: 100%;
    opacity: 0;
    height: 100%;
    top: 0;
}
.radioStyle:checked + label{
    background: #D5D5D5;
}
.labelStyle{
    background: #F5F2F1;
    padding: 10px 40px;
    border-radius: 10px 0 0 10px;
    color: #7B6F67;
    font-family: 'Aspira';
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
}
.leftLabel{
    @extend .labelStyle;
    border-right: 1px solid #D5D5D5;
}
.rightLabel{
    @extend .labelStyle;
    border-radius: 0 10px 10px 0;
}
.btnsDiv{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}
.btnStyle{
    width: 33%;
    font-family: 'Aspira';
}
.leftBtnStyle{
    @extend .btnStyle;
    margin-right: 20px;
}