.mainDiv{
    padding: 40px 30px;
    max-width: 300px;
    // width: 70%;
}
.flexDiv{
    display: flex;
    margin-bottom: 10px;
    position: relative;
    padding: 10px 0;
}
.selectedBtn {
    background: #F5F2F1;
    border-radius: 10px;
}
.imgStyle{
    align-self: center;
    margin: 0 20px;
}
.wrapperDiv{
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin: auto;
    padding: 50px 0 20px 0;
}
.selectedElement{
    background: #F5F2F1;
    border-radius: 10px;
    padding: 17px 0;
}
.menuItem{
    text-align: left;
    margin: 0;
    font-size: 15px;
    font-family: 'Aspira';
    color: #7B6F67;
}
.wrapperFlexDiv{
    display: flex;
    flex-direction: row !important;
}
.content{
    width: 100%;
    background-color: #F5F2F1;
    height: 100%;
}
.content > div{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.radioStyle{
    position: absolute;
    width: 100%;
    opacity: 0;
    height: 100%;
    top: 0;
}
