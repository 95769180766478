.mainDiv {
  background: #ffffff;
  border-radius: 30px;
  padding: 40px;
  max-width: 1470px;
  width: 90%;
  margin: 40px auto;
}
.headerStyle {
  display: flex;
  max-width: 350px;
  width: 100%;
  margin: auto auto 40px auto;
}
.titleDiv {
  background: #f5f2f1;
  display: grid;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
  grid-template-columns: 27% 17% 13% 32% 11%;
}
.titleStyle {
  color: #7b6f67;
  font-size: 24px;
  font-weight: bold;
  font-family: "Aspira";
  text-align: left;
  margin-bottom: 0;
  align-self: flex-end;
}
.centeredTitle {
  @extend .titleStyle;
  text-align: center;
}
.rowDiv {
  display: grid;
  border: 1px solid #f5f2f1;
  grid-template-columns: 28% 17% 11% 33% 11%;
}
.cellStyle {
  font-size: 20px;
  font-family: "Aspira";
  text-align: left;
  border-right: 1px solid #f5f2f1;
  margin-bottom: 0;
  padding: 13px 20px;
  color: #7b6f67;
}
.middleCell {
  @extend .cellStyle;
  text-align: center;
}
.btnsDiv {
  display: flex;
  justify-content: space-between;
  padding: 13px;
  border-right: 1px solid #f5f2f1;
}
.voteBtnStyle {
  @extend .btnsDiv;
  border-right: none;
  margin: 0 auto;
}
.btnStyle {
  font-size: 12px;
  max-width: 100px;
  padding: 5px 20px;
  line-height: 15px;
  max-height: 24px;
  background: #f8deae;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: #7b6f67;
}
.voteBtn {
  @extend .btnStyle;
  background: #228d68;
  color: #ffffff;
}
.btnStyle:hover {
  background: #f8deae;
}
.btnStyle:focus {
  background: #f8deae;
}

.popupDiv {
  width: 1000px;
  background: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.topicsPopupDiv{
    @extend .popupDiv;
    padding: 24px;
    margin-top: 15px;
}
.popupWrapper{
    @extend .popupDiv;
    background: #F5F2F1;
    padding: 40px;
    width: unset;
    position: relative;
}
.popupTitle{
    font-size: 21px;
    font-family: 'Aspira';
    font-weight: bold;
    text-align: left;
    color: #7b6f67;
}
.topicsPopupClose{
    position: absolute;
    top: 20px;
    right: 20px;
}
.closeStyle {
  align-self: flex-end;
}
.agreeBtn {
  background: #228d68;
  font-size: 10px;
  border-radius: 5px;
  padding: 5px 15px;
  font-family: 'Aspira';
}
.disagreeBtn {
  background: #d1202f;
  font-size: 10px;
  border-radius: 5px;
  padding: 5px 15px;
  font-family: 'Aspira';
}
