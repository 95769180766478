.mainDiv{
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background: #F5F2F1;
    padding: 15px;
    margin: 20px 20px 0 20px;
}
.redCircle{
    width: 15px;
    height: 15px;
    background: #D1202F;
    border-radius: 50%;
    align-self: center;
    margin-right: 10px;
}
.title{
    color: #D1202F;
    font-size: 16px;
    align-self: center;
}
.arrowStyle{
    align-self: center;
    width: 16px;
}
.rotatedArrow {
    @extend .arrowStyle;
    transform: rotate(180deg);
}
// .collapse < .mainDiv{
//     border-radius: 10px 10px 0 0;
// }
.flexDiv{
    display: flex;
}
.expandedDiv{
    background: #FFF7F7;
    margin: 0 20px 20px 20px;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.expandedDivTitle{
    font-size: 16px;
    font-weight: normal;
    color: #7B6F67;
    text-align: left;
    margin-bottom: 0;
}
.expandedDivText{
  @extend .expandedDivTitle;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-self: center;
}
.topicsDiv{
    @extend .expandedDivText;
    border: 1px solid #7B6F67;
    padding: 1px 5px;
    margin: 5px;
}
.detailsDiv{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #F5F2F1;
}
.iconsDiv{
    margin: 5px 0 10px 0;
}
.editIcon{
    margin-right: 20px;
}